
import { defineComponent } from "@vue/runtime-core";
import AppPage from "@/components/AppPage.vue";
import DataTable from "@/components/DataTable.vue";
import { Pagination, Sorting } from "@/use/types";

import axios from "axios";
import moment from "moment-timezone";

interface IDataModel {
  loading: boolean;
  filter: IQuery;
  rows: any[];
  totalRows: number | null;
  columns: Column[];
  totals: any[];
}

interface IQuery {
  pagination: null | Pagination;
  sorting: Sorting;
}

interface Column {
  id: number | string;
  name: string;
  selected: boolean;
  freezed?: boolean;
}

export function getDefaultQuery(): IQuery {
  return {
    sorting: { column: "created_at", direction: "desc" },
    pagination: { limit: 10, offset: 0 },
  };
}

const dataColumns = [
  {
    id: "created_at",
    name: "Дата",
    selected: true,
    sortable: true,
    freezed: true,
  },
  {
    id: "name",
    name: "Домен",
    selected: true,
    sortable: true,
    freezed: true,
  },
  {
    id: "users",
    name: "Users",
    selected: true,
    sortable: true,
    freezed: true,
  },
  {
    id: "subs",
    name: "Subs",
    selected: true,
    sortable: true,
  },
];

export default defineComponent({
  components: {
    AppPage,
    DataTable,
  },
  data(): IDataModel {
    return {
      loading: false,
      rows: [],
      columns: dataColumns,
      totalRows: null,
      filter: getDefaultQuery(),
      totals: [],
    };
  },
  mounted: function () {
    this.$watch("filter.pagination", this.load);
    this.$watch("filter.sorting", this.load);
  },
  async created() {
    this.load();
  },
  methods: {
    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },
    async clear() {
      this.filter = getDefaultQuery();
      this.load();
    },
    async load() {
      this.loading = true;
      this.rows = [];
      this.totalRows = 0;
      this.totals = [];

      try {
        const { data } = await axios.get("/api/push", {
          params: {
            q: this.filter,
          },
        });
        this.rows = data.rows;
        this.totalRows = data.totalRows;
        this.totals = data.totals;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
  },
});
