<template>
  <div class="table__form">
    <div class="table__filters">
      <slot />
      <div class="table__settings" v-if="pageType === 'default'">
        <div class="settings-item setting-trigger" v-if="hasFilterSettings">
          <table-column-selector
            :value="columns"
            @update:value="$emit('update:columns', $event)"
          />
        </div>
        <div class="settings-item" v-if="hasDownload">
          <button
            class="export-btn"
            disabled
            @click.prevent="$emit('download')"
          >
            <span>Экспорт CSV</span>
            <div class="icon-export icon"></div>
          </button>
        </div>
    </div>
    </div>
    <div class="table__actions">
      <div class="table__grouping" v-if="mainGrouping && secondaryGrouping">
        <Dropdown
          label="Выберите параметр"
          :value="mainGroupingValue"
          :options="mainGrouping"
          :with_search="false"
          @update:value="$emit('update:mainGroupingValue', $event)"
          :hide-clear-value-icon="true"
        />
        <Dropdown
          label="Выберите параметр"
          :value="secondaryGroupingValue"
          :options="secondaryGrouping"
          :with_search="false"
          @update:value="$emit('update:secondaryGroupingValue', $event)"
        />
      </div>
      <div class="table__buttons" v-if="hasFilterButtons">
        <Button
          className="btn-primary"
          class="_filled"
          type="submit"
          :disabled="disabled"
          @click.prevent="$emit('apply')"
          text="Применить"
        />
        <Button
          className="_outlined"
          :disabled="disabled"
          @click.prevent="$emit('clear')"
          text="Очистить"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import TableColumnSelector from "@/components/TableColumnSelector";
import Dropdown from "@/components/Dropdown";
export default {
  components: {
    Button,
    TableColumnSelector,
    Dropdown,
  },
  props: {
    disabled: Boolean,
    columns: Array,
    hasFilterButtons: Boolean,
    hasFilterSettings: Boolean,
    hasDownload: Boolean,
    pageType: {
      type: String,
      default: "default",
    },
    mainGrouping: Array,
    mainGroupingValue: String,
    secondaryGrouping: Array,
    secondaryGroupingValue: String,
  },
};
</script>

<style lang="scss" scoped>
.table {
  &__form {
    padding: 30px 0;
    border-bottom: 1px solid fade-out(#8080a7, 0.75);
    display: flex;
    flex-wrap: wrap;
  }

  &__grouping {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    > div:last-child {
      margin-right: 30px;
    }
  }

  &__actions {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 30px;
  }

  &__settings {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 1600px) {
      flex: 0 0 100%;
      padding-top: 16px;
    }

    @media screen and (min-width: 1601px) {
      padding-top: 24px;
      flex: 1 1 auto;
    }

    .settings-item {
      max-width: 200px;
    }
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }

  &__buttons {
    margin-top: 24px;
    gap: 16px;
    display: flex;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
}

.export-btn {
  display: flex;
  align-items: center;
  background: #d8e5ff;
  border-radius: 50px;
  padding: 8px 16px;
  height: 40px;
  align-self: flex-end;
  transition: background-color 0.2s ease, color 0.2s ease;
  user-select: none;

  &[disabled] {
    opacity: 0.7;
    pointer-events: none;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 0;
  }

  .icon {
    @media screen and (max-width: 767px) {
      margin-left: 5px;
    }

    @media screen and (min-width: 768px) {
      margin-left: 15px;
    }
  }

  &:hover {
    background-color: #3a58ff;
    color: #ffffff;
  }
}
</style>
