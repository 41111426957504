
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    label: String,
    hideClearValueIcon: {
      type: Boolean,
      default: () => false,
    },
    value: [String, Number],
    options: Array,
    error: { type: String, default: () => null },
    required: {
      type: Boolean,
      default: false,
    },
    limit: Number,
  },
  data() {
    return { isOpened: false, search: "" };
  },
  watch: {
    search(newValue) {
      this.$emit("search", newValue);
    },
    value() {
      this.search = "";
    },
  },
  computed: {
    effectiveOptions() {
      if (this.limit) {
        return this.options?.slice(0, this.limit);
      }

      return this.options;
    },
    placeholder() {
      if (this.value) {
        const o = this.options!.find((o: any): o is any => o.id === this.value);
        return o ? o.name : this.label;
      }
      return this.label;
    },
  },
  methods: {
    toggle(e: any) {
      this.isOpened = !this.isOpened;
      if (!this.isOpened) {
        e.target.blur();
      }
    },
    closeAndEmit(o: any) {
      this.isOpened = false;
      this.search = o.name;
      this.$emit("update:value", o.id);
    },
    clearValue() {
      this.search = "";
      this.$emit("update:value", "");
      this.$emit("search", "");
    },
  },
});
