<template>
  <a
    :class="['table__settings-button', { _active: isSettingsVisible }]"
    href="#"
    ref="link"
    @click.prevent="isSettingsVisible = !isSettingsVisible"
  >
    <span>Настроить поля</span>
    <!--    <div class="icon-settings"></div>-->
  </a>
  <div class="table__settings-body dropdown-body" v-if="isSettingsVisible">
    <div class="triangle-with-shadow"></div>
    <div class="settings-wrapper dropdown-wrapper">
      <label
        class="table__settings-item dropdown-item"
        :class="`table_settings-option-${c.group || c.id}`"
        :id="`table_settings-option-${c.id}`"
        v-for="c of value"
        :key="c.id"
      >
        <span>{{ c.name }}</span>
        <input
          type="checkbox"
          name="table_settings"
          :value="c.id"
          :checked="c.selected"
          :disabled="c.freezed"
          @input="toggle(c)"
        />
        <div class="check"><i class="icon-ok icon"></i></div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
  },
  data() {
    return {
      isSettingsVisible: false,
    };
  },
  methods: {
    toggle(c) {
      if (c.freezed) {
        return;
      }
      let columns = this.value.map((x) => {
        return x.id === c.id ? { ...x, selected: !c.selected } : x;
      });

      this.$emit("update:value", columns);
    },
  },
};
</script>

<style lang="scss" scoped>
.table_settings-option-1#table_settings-option-sid4 {
  border-bottom: 1px solid #1f8efa;
}

.table__settings {
  &-button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid #3a58ff;
    border-radius: 50px;
    color: #3a58ff;
    transition: background-color 0.2s ease, color 0.2s ease;
    background: #fff;
    max-width: 200px;
    font-size: 16px;
    line-height: 24px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 0;
    }

    .icon {
      margin-left: 15px;
    }

    > span {
      white-space: nowrap;
    }

    &:hover,
    &._active {
      background-color: #3a58ff;
      color: #ffffff;
    }
  }

  &-body {
    width: 280px;
    position: absolute;
    top: calc(100% + 5px);
    border-radius: 4px;
    box-shadow: 0 35px 80px rgba(133, 133, 175, 0.6);
    background: #fff;
    z-index: 5;
    padding: 16px;
  }

  &-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;

    & + & {
      margin-top: 10px;
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;
      display: block;
      left: 0;
      top: 0;
    }

    > span {
      order: 1;
    }

    .check {
      width: 18px;
      height: 18px;
      border: 1px solid rgba(124, 131, 253, 0.25);
      border-radius: 2px;
      margin-right: 14px;
      order: 0;
      background: #dfdfff;

      .icon {
        width: 100%;
        height: 100%;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        color: #fff;
      }
    }

    input:checked + .check {
      background: #7c83fd;

      .icon {
        opacity: 1;
      }
    }
  }
}
</style>
