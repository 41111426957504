<template>
  <div :class="htmlClass">
    <table-filter
      v-if="hasFilterButtons || hasFilterSettings || hasDownload"
      :disabled="loading"
      :columns="columns"
      @update:columns="$emit('update:columns', $event)"
      @update:mainGroupingValue="$emit('update:mainGroupingValue', $event)"
      @update:secondaryGroupingValue="$emit('update:secondaryGroupingValue', $event)"
      @apply="$emit('apply')"
      @clear="$emit('clear')"
      @download="$emit('download')"
      :has-filter-buttons="hasFilterButtons"
      :has-filter-settings="hasFilterSettings"
      :has-download="hasDownload"
      :page-type="pageType"
      :mainGrouping="mainGrouping"
      :secondaryGrouping="secondaryGrouping"
      :mainGroupingValue="mainGroupingValue"
      :secondaryGroupingValue="secondaryGroupingValue"
    >
      <slot v-if="pageType === 'default'" />
      <div class="table__form" v-else>
        <div class="top-filters">
          <slot name="top" />
          <div class="table__settings">
            <div class="settings-item setting-trigger" v-if="hasFilterSettings">
              <table-column-selector
                :value="columns"
                @update:value="$emit('update:columns', $event)"
              />
            </div>
            <div class="settings-item" v-if="hasDownload">
              <button
                class="export-btn"
                disabled
                @click.prevent="$emit('download')"
              >
                <span>Экспорт CSV</span>
                <div class="icon-export icon"></div>
              </button>
            </div>
          </div>
        </div>
        <div class="bottom-filters">
          <slot name="bottom" />
        </div>
      </div>
    </table-filter>
    <div class="table-wrapper">
      <table
        v-if="rows.length || (totals && rows.length)"
        ref="table"
        :class="subClassName"
      >
        <thead>
          <tr>
            <th
              v-for="c of visibleColumns"
              :key="c.id"
              @click="c.sortable && filter.sorting && toggleSorting(c.id)"
            >
              <div :class="['th__inner', c.htmlClass]">
                {{ c.name }}
                <div class="sort-icons" v-if="c.sortable && filter.sorting">
                  <i
                    :class="[
                      'icon-sort',
                      'icon',
                      {
                        _up: getArrowClassTrigger(c.id, 'desc'),
                        _down: getArrowClassTrigger(c.id, 'asc'),
                      },
                    ]"
                  ></i>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="rows?.length">
          <tr v-for="r of rows" :key="r[columns[0].id]">
            <td
              v-for="c of visibleColumns"
              :key="c.id"
              :class="c.htmlClass || ''"
            >
              <slot :name="`row.${c.id}`" :row="r" :column="c">
                <span v-if="c.searchable" v-html="highlight(r[c.id])" />
                <span v-else>{{ r[c.id] || "-" }}</span>
              </slot>
            </td>
            <td v-if="isActionsExist">
              <slot name="actions" :row="r"> </slot>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="totals">
          <tr>
            <td v-for="(c, i) of visibleColumns" :key="c.id" :class="c.htmlClass">
              {{
                totals[c.id] ? totals[c.id] : !totals[c.id] && i === 0 ? "Всего" : "-"
              }}
            </td>
          </tr>
        </tfoot>
      </table>
      <div v-else class="pure-content-message">
        <img src="img/pure__data.svg" alt="" />
        <p>
          <slot name="no-data">Нет данных</slot>
        </p>
      </div>
    </div>
  </div>
  <pagination
    v-if="rows.length"
    :value="filter.pagination"
    @update:value="$emit('update:filter', { ...filter, pagination: $event })"
    :total-rows="totalRows"
    :text-forms="textForms"
  />
</template>

<script>
import TableFilter from "./TableFilter.vue";
import Pagination from "./Pagination.vue";
import TableColumnSelector from "./TableColumnSelector.vue";

import xss from "xss";

export default {
  components: { TableFilter, Pagination, TableColumnSelector },
  emits: [
    "apply",
    "clear",
    "download",
    "update:filter",
    "update:columns",
    "update:mainGroupingValue",
    "update:secondaryGroupingValue",
  ],
  props: {
    mainGrouping: { type: Array, default: () => [] },
    mainGroupingValue: String,
    secondaryGroupingValue: String,
    secondaryGrouping: { type: Array, default: () => [] },
    loading: Boolean,
    filter: Object,
    columns: Array,
    rows: Array,
    totals: { type: Object, default: null },
    totalRows: Number,
    hasFilterSettings: { type: Boolean, default: () => false },
    hasDownload: { type: Boolean, default: () => false },
    hasFilterButtons: { type: Boolean, default: () => false },
    htmlClass: String,
    textForms: Array,
    subClassName: String,
    pageType: {
      type: String,
      default: "default",
    },
    isActionsExist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortedData: null,
    };
  },
  computed: {
    visibleColumns() {
      return this.columns.filter((c) => c.selected);
    },
    columnsWithTextTypeValues() {
      let columns = this.columns.filter(
        (x) => x?.type === "text" && x.sortable
      );
      return columns.map((x) => x.id);
    },
  },
  methods: {
    getArrowClassTrigger(column_id, direction) {
      return (
        (this.filter.sorting.column === column_id &&
          this.filter.sorting.direction === direction &&
          !this.columnsWithTextTypeValues.includes(column_id)) ||
        (this.filter.sorting.column === column_id &&
          this.columnsWithTextTypeValues.includes(column_id) &&
          this.filter.sorting.direction ===
            (direction === "desc" ? "asc" : "desc"))
      );
    },
    highlight(word) {
      let sanitized = xss(word, {
        whiteList: [],
      });

      let query = this.filter.search;
      let check = new RegExp(query, "ig");

      if (sanitized !== null) {
        if (query && query.length) {
          return sanitized.replace(check, (matchedText) => {
            return "<strong>" + matchedText + "</strong>";
          });
        } else {
          return sanitized || "-";
        }
      } else {
        return "–";
      }
    },
    toggleSorting(column) {
      const { sorting } = this.filter;
      if (sorting.column === column) {
        const direction = sorting.direction === "asc" ? "desc" : "asc";
        this.$emit("update:filter", {
          ...this.filter,
          sorting: { column, direction },
        });
      } else {
        this.$emit("update:filter", {
          ...this.filter,
          sorting: { column, direction: "asc" },
        });
      }
    },
    updateFilter(e) {
      if (this.filter.pagination.limit === e.limit) {
        this.$emit("update:filter", {
          ...this.filter,
          pagination: e,
          sorting: { column: "created_at", direction: "asc" },
        });
      } else {
        this.$emit("update:filter", { ...this.filter, pagination: e });
      }
    },
  },
  mounted: function () {
    const table = this.$refs.table;
    if (table) {
      const thead = table.querySelector("thead");
      const th = thead.querySelectorAll("th");

      window.addEventListener("scroll", function () {
        const table_top = table.getBoundingClientRect().y;
        if (table_top < 0) {
          thead.style.transform = "translateY(" + Math.abs(table_top) + "px)";
          thead.style.background = "#fff";
          th.forEach(
            (el) =>
              (el.style.borderBottom = "1px solid rgba(128, 128, 167, 0.25)")
          );
        } else {
          thead.removeAttribute("style");
          th.forEach((el) => el.removeAttribute("style"));
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

.table-wrapper {
  overflow-x: auto;
  padding-bottom: 85px;
  padding-top: 30px;
}

.table {
  &__settings {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 1600px) {
      flex: 0 0 100%;
    }

    @media screen and (min-width: 1601px) {
      padding-top: 24px;
      margin-left: auto;
    }

    .settings-item {
      max-width: 200px;
    }
  }
}

table {
  width: 100%;
  border-spacing: 0 8px;
  border-collapse: separate;

  &.advertiser-table {
    max-width: 1200px;
    min-width: 800px;
  }

  .icon {
    cursor: pointer;
    font-size: 14px;

    &._up {
      color: $color-blue-light;
    }

    &._down {
      color: red;
    }
  }

  .sort-icons {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }

  .th__inner {
    display: flex;
    align-items: center;

    &.finance-column {
      justify-content: flex-end;
    }
  }

  th {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: fade-out($color-base, 0.6);
    text-align: start;
    padding: 20px 10px;
    cursor: pointer;
    white-space: nowrap;
  }

  thead {
    z-index: 4;
  }

  tbody {
    td {
      background: #fff;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      height: 88px;
      padding: 8px 20px;

      &.highlighted-column {
        background: rgba(189, 166, 255, 0.45);
      }

      &.date-column {
        white-space: nowrap;
      }

      span {
        strong {
          color: $color-blue-light;
        }
      }

      &.finance-column {
        text-align: right;
      }
    }
  }

  tfoot {
    td {
      background: #dfdfff;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      height: 88px;
      padding: 8px 20px;

      &.finance-column {
        text-align: right;
      }
    }
  }

  tfoot {
    td {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      height: 88px;
      padding: 8px 20px;

      &.highlighted-column {
        background: rgba(189, 166, 255, 0.45);
      }

      &:not(.highlighted-column) {
        background: #dfdfff;
      }
    }
  }

  .news__table--h {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .news__table--image {
    width: 135px;
  }
}

.top-filters,
.bottom-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 16px;
}

.top-filters {
  padding-bottom: 30px;
  border-bottom: 1px solid fade-out(#8080a7, 0.75);
}

.export-btn {
  display: flex;
  align-items: center;
  background: #d8e5ff;
  border-radius: 50px;
  padding: 8px 16px;
  height: 40px;
  align-self: flex-end;
  transition: background-color 0.2s ease, color 0.2s ease;
  user-select: none;

  &[disabled] {
    opacity: 0.7;
    pointer-events: none;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 0;
  }

  .icon {
    @media screen and (max-width: 767px) {
      margin-left: 5px;
    }

    @media screen and (min-width: 768px) {
      margin-left: 15px;
    }
  }

  &:hover {
    background-color: #3a58ff;
    color: #ffffff;
  }
}
</style>
